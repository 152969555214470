.facebook-embed-container {
    position: relative;
    padding-bottom: 56.25%; /* Aspect ratio of 16:9 */
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  
  .facebook-embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  