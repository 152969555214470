/* .flipbook {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .pdf-page {
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: white;
    padding: 20px;
    margin: 20px 0;
    position: relative;
  }
  
  .controls {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .controls button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .controls button:disabled {
    background-color: #aaa;
  }
  
  .controls button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .controls p {
    margin: 0;
    font-size: 1em;
  }

  .react-pdf__Page__annotations {
    display: none !important;
  }
   */
   .react-pdf__Page__annotations {
        display: none !important;
        
    }
    .react-pdf__Page__canvas{
      width: 70% !important;
      margin-left: 15% !important;
    }
   .flipbook {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    height: 842px;
  }
  
  .pdf-page {
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: white;
    padding: 20px;
    padding-left: 100px;
    margin: 20px 0;
    height: var(--rounded-height);
  }
  
  /* Controls */
  .controls {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1f1f33;
  }
  
  /* .controls button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  } */
  
  .controls button {
    position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  }

  .nav-button.left {
    left: 10px;
    font-weight: 900;
  }
  
  .nav-button.right {
    right: 10px;
    font-weight: 900;
  }

  
  .controls button:disabled {
    background-color: #aaa;
  }
  
  .controls button:hover:not(:disabled) {
    background-color: #C08E2D;
    color: #1f1f33;
  }
  
  .controls p {
    margin: 0;
    font-size: 1em;
  }
  
  .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 842px;
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #C08E2D;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }


  /* Responsive styles */
  @media (max-width: 768px) {
    .flipbook {
      width: 95%;
      max-width: none;
      box-shadow: none;
      height: 612px;
    }
  
    .react-pdf__Page__canvas{
      height: 612px !important;
      width: 100% !important;
      margin-left: 0% !important;
    }

    .pdf-page {
      padding: 10px;
      margin: 10px 0;
      height: auto; /* Adjust the height or use percentage */
    }
  
    .controls {
      flex-direction: column;
    }
  
    .controls button {
      margin: 5px 0;
      padding: 8px 16px;
    }
  
    .controls p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .controls button {
      padding: 6px 12px;
      font-size: 0.8em;
    }
  
    .controls p {
      font-size: 0.8em;
    }
  }