.App {
  font-family: sans-serif;
  text-align: center;
}

.error {
  border: 1px solid red !important;
}

.success {
  border: 1px solid green !important;
}

.marginTop {
  margin-top: 40px;
  margin-bottom: 40px;
}

#myInput::placeholder {
  color: #1f1f33;
} 


#myInput-0{
  color: #1f1f33;
  font-size: 35px;
  padding: 8px;
  font-weight: 600;
  border: none;
}

#myInput-1{
  color: #1f1f33;
  font-size: 35px;
  padding: 8px;
  font-weight: 600;
  border: none;
}

#myInput-2{
  color: #1f1f33;
  font-size: 35px;
  padding: 8px;
  font-weight: 600;
  border: none;
}

#myInput-3{
  color: #1f1f33;
  font-size: 35px;
  padding: 8px;
  font-weight: 600;
  border: none;
}

#myInput-4{
  color: #1f1f33;
  font-size: 35px;
  padding: 8px;
  font-weight: 600;
  border: none;
}

#myInput-5{
  color: #1f1f33;
  font-size: 35px;
  padding: 8px;
  font-weight: 600;
  border: none;
}

@media only screen 
and (max-device-width: 600px)
 {
  #myInput-0{
    color: #1f1f33;
    font-size: 20px;
    padding: 3px;
    font-weight: 500;
    border: none;
  }
  
  #myInput-1{
    color: #1f1f33;
    font-size: 20px;
    padding: 3px;
    font-weight: 500;
    border: none;
  }
  
  #myInput-2{
    color: #1f1f33;
    font-size: 20px;
    padding: 3px;
    font-weight: 500;
    border: none;
  }
  
  #myInput-3{
    color: #1f1f33;
    font-size: 20px;
    padding: 3px;
    font-weight: 500;
    border: none;
  }
  
  #myInput-4{
    color: #1f1f33;
    font-size: 20px;
    padding: 3px;
    font-weight: 500;
    border: none;
  }
  
  #myInput-5{
    color: #1f1f33;
    font-size: 18px;
    padding: 3px;
    font-weight: 500;
    border: none;
    text-align: center;
  }

 }

